<template>
  <div>
    <div>
      <!-- Home page content -->
      <main role="main" class="container">
        <div class="text-center mt-5 mb-5">
          <h1 class="text-center mb-2">
            Achetez 2 boîtes spécialement identifiées de céréales Kellogg’s<sup>®</sup> et recevez un LIVRE GRATUIT<sup>††</sup>
          </h1>
          <p class="page-subheader">
            Consultez les <a href="https://snipp.us/!ctbeL" target="_blank">modalités</a> pour obtenir tous les détails.
          </p>
        </div>
        <h2 class="text-center mb-4">Comment participer</h2>

        <div class="row text-center mb-5 justify-content-center htsect">
          <div class="col-md-3">
            <div class="">
              <img src="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/shop.png"
                alt="MAGASINEZ" class="img-fluid mb-3" />
              <h3>MAGASINEZ</h3>
              <p>
                Achetez 2 boîtes spécialement identifiées de céréales Kellogg’s<sup>®</sup> d’ici le 31 janvier 2025 en
                une seule transaction.
              </p>
            </div>
          </div>

          <div class="col-md-3">
            <div class="">
              <img src="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/snap.png"
                alt="PRENEZ UNE PHOTO" class="img-fluid mb-3" />
              <h3>PRENEZ UNE PHOTO</h3>
              <p>
                Prenez une photo claire de votre preuve d’achat complète.
              </p>
            </div>
          </div>

          <div class="col-md-3">
            <div class="">
              <img src="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/upload.png"
                alt="TÉLÉVERSEZ ET INSCRIVEZ-VOUS" class="img-fluid mb-3" />
              <h3>TÉLÉVERSEZ ET INSCRIVEZ-VOUS</h3>
              <p>
                Fournissez vos renseignements personnels et téléversez votre preuve d’achat.
              </p>
            </div>
          </div>

          <div class="col-md-3">
            <div class="">
              <img src="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/book.png"
                alt="CHOISISSEZ" class="img-fluid mb-3" />
              <h3>CHOISISSEZ</h3>
              <p>
                Après vérification de votre preuve d’achat, vous recevrez un courriel de confirmation et une demande de
                sélection de votre livre.
              </p>
            </div>
          </div>
        </div>
        <div class="text-center readytosubmit mt-5 mb-5 pb-5">
          <div class="row d-flex justify-content-between align-items-center">
            <div class="col-12">
              <h2 class="">Prêt à soumettre?</h2>
              <p>Cliquez sur « Commencer » ci-dessous pour soumettre votre preuve d’achat et recevoir votre livre!</p>
              <p><router-link to="/Upload" class="btn btn-link">Commencer</router-link></p>
              <p class="small"><router-link to="/ParticipatingProducts">Cliquez ici pour voir tous les produits
                  participants.</router-link></p>
              <p class="small">Que dois-je faire si je n'arrive pas à participer à la promotion? <br><router-link to="/Support">Visitez notre FAQ</router-link>.</p>
              <h2 class="mt-4">Quels sont les livres disponibles?</h2>
              <p><router-link to="/BookLibrary" class="btn btn-link">Afficher la bibliothèque</router-link></p>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>

export default {
  components: {},
  props: {
    startDate: {
      type: Date,
      required: true,
    },
    endDate: {
      type: Date,
      required: true,
    },
  },
  data() {
    return {
      showComponent: false,
    };
  },
};
</script>

<style scoped>
.htsect h3 {
  font-size: 1.4rem;
}

@media screen and (max-width: 575px) {}
</style>
