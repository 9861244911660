<template>
  <header>
    <div class="">
      <nav class="navbar navbar-expand-md align-items-sm-center justify-content-end">
        <router-link to="/Home" class="navbar-brand">
          <img src="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/brand-logo.png"
            alt="logo" class="img-fluid" />
        </router-link>
        <button class="navbar-toggler" type="button" @click="toggleCollapse" aria-controls="navbar-Nav"
          aria-expanded="false" aria-label="Toggle navigation" :class="{ active: isCollapsed }">
          <!-- Add some icon or text for toggling here -->
        </button>
        <div :class="{ show: isCollapsed }"
          class="collapse navbar-collapse justify-content-between my-2 align-items-center" id="navbar-Nav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link to="/Home" class="nav-link text-uppercase">Accueil</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/Upload" class="nav-link text-uppercase">Téléverser</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/BookLibrary" class="nav-link text-uppercase">Bibliothèque de livres</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/ParticipatingProducts" class="nav-link text-uppercase">Produits participants</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/Support" class="nav-link text-uppercase">Foire aux questions</router-link>
            </li>
          </ul>
        </div>
        <div class="toogle-country-list">
          <ul class="list-inline">
            <li><a :href="getLanguageLink('en')">English</a></li>
            <li><a :href="getLanguageLink('fr')">Français</a></li>
          </ul>
        </div>
      </nav>

    </div>
  </header>

  <banner></banner>
</template>

<script>
import banner from '../UI/Banner.vue';
export default {
  components: { banner },
  data() {
    return {
      isCollapsed: false,
    };
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    getLanguageLink(languageCode) {
  const currentPath = this.$route.path;
  const currentHost = window.location.hostname;
  let baseUrl;

  // Determine environment based on hostname
  const isStaging = currentHost.includes('staging');

  if (languageCode === 'en') {
    baseUrl = isStaging 
      ? 'https://staging-feedingreadingen.snipp.us'  // Staging URL for English
      : 'https://feedingreading.ca';          // Production URL for English
  } else if (languageCode === 'fr') {
    baseUrl = isStaging 
      ? 'https://staging-feedingreadingfr.snipp.us'   // Staging URL for French
      : 'https://nourrirlalecture.ca';          // Production URL for French
  }

  return `${baseUrl}${currentPath}`;
}
  },
  mounted() {
    // Close menu on route change
    this.$router.afterEach(() => {
      this.isCollapsed = false;
    });
  }
};
</script>

<style scoped>
header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  padding: 1rem 0;
}

.navbar {
  padding: 0;
}

ul.navbar-nav {
  width: 100%;
}

#navbar-Nav>ul>li a {
  color: #fff;
  line-height: 1;
  padding: 0.7rem;
  text-transform: uppercase;
  font-weight: 600;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  width: 15%;
  margin-right: 2rem;
}

#navbar-Nav>ul>li+li {
  margin-left: 2%;
}

.list-inline>li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}

.social-icon {
  font-size: 2rem;
  border-radius: 5px;
  color: #f26a32;
}

header .navbar {
  padding: 1rem 1.5rem;
  justify-content: space-between;
}

.nav-link {
  background: transparent;
  cursor: pointer;
  color: #000;
  display: inline-block;
  text-decoration: none;
}

.nav-link:hover,
.nav-link.active {
  color: #d31245 !important;
}

.toogle-country-list {
  margin: 0;
  width: 16%;
}

.toogle-country-list ul {
  padding: 0;
  margin: 0;
}

.toogle-country-list ul li {
  position: relative;
}

.toogle-country-list ul li a {
  font-weight: 600;
  color: #fff;
  width: auto;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: none;
}

.toogle-country-list ul li a:hover {
  color: #d31245;
}

.toogle-country-list ul li:first-child:after {
  content: "|";
  color: #fff;
  position: absolute;
  right: 0;
  top: 0;
}

.toogle-country-list ul li:first-child {
  position: relative;
  margin: 0 10px 0 0;
  padding: 0 20px 0 0;
}

/* ==========================================================================
   mobile menu toggler Style
   ========================================================================== */
header .navbar .navbar-toggler {
  display: none;
  padding: 0px;
  border: 0;
  padding-right: 0;
}

.navbar-toggler {
  position: relative;
  top: 0;
  right: 0;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  z-index: 1;
  margin: 5px 10px;
}

.navbar-toggler::before {
  content: "";
  position: absolute;
  width: 35px;
  height: 5px;
  background: #fff;
  transform: translateY(-10px);
  box-shadow: 0 10px #fff;
  transition: 0.5s;
  border-radius: 20px;
}

.navbar-toggler::after {
  content: "";
  position: absolute;
  width: 35px;
  height: 5px;
  background: #fff;
  transform: translateY(10px);
  transition: 0.5s;
  border-radius: 20px;
}

.navbar-toggler.active::before {
  transform: translateY(0px) rotate(45deg);
  box-shadow: 0 0 #fff;
  background: #fff;
}

.navbar-toggler.active::after {
  transform: translateY(0px) rotate(-45deg);
  background: #fff;
}


@media screen and (max-width: 767px) {
  ul.navbar-nav {
    width: 100%;
  }

  header .navbar .navbar-toggler {
    display: flex;
    order: 3;
  }

  header {
    padding: 0;
  }

  div#navbar-Nav {
    position: absolute;
    top: 0;
    left: 0;
    background: #513629;
    padding-top: 50px;
    text-align: left;
    width: 100%;
    margin: 0 !important;
  }

  #faqAccordion .accordion-button {
    font-size: 1.2rem;
  }

  header .navbar {
    padding: 0;
  }

  .navbar-brand {
    display: none;
  }

  ul.navbar-nav li {
    display: block;
    margin: 0 !important;
  }
  .toogle-country-list{
    width: auto;
}
}

@media screen and (max-width: 575px) {
  .navigation {
    margin-left: 0px !important;
  }

  .navigation img {
    max-width: 100%;
  }
}
</style>
