<template>
  <!-- import styles if needed -->
  <PageLoader class="mb-5 mt-5" v-if="isLoading"></PageLoader>
  <div v-if="is_loaded" v-show="!isLoading">
    <!-- import styles if needed -->

    <div v-if="is_loaded">
      <div id="current-user-wrapper">
        <snipp-current-user :campaign-id="campaignId" :user-status="user_status" language="fr"
          @show-registration="showRegistration" @snipp-logout="handleSnippLogout" @show-confirmation="showConfirmation" custom-css="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/kellogs_overrides.css"
          @current-user="handleCurrentUser"></snipp-current-user>
      </div>
      <div id="registration-wrapper" v-if="registration_enabled">
        <snipp-registration app-source="CampaignWidget" refresh-reload="true" language="fr" :campaign-id="campaignId"
          :show-confirmation="show_confirmation" custom-css="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/kellogs_overrides.css" :is-logged-out="is_logged_out"
          @is-registered="handleIsRegistered"></snipp-registration>
      </div>
      <div id="thanks-text" v-if="upload_enabled === 'upload_again'">
        <h1 class="text-uppercase mb-3">Merci !</h1>
        <p>
          Nous avons bien reçu votre soumission. Le traitement peut prendre jusqu’à 72 heures. Une fois votre preuve d’achat validée, vous recevrez un courriel contenant les instructions pour sélectionner votre livre. 
        <small class="d-block">Voir <a href="https://snipp.us/!ctbeL" target="_blank">les modalités</a>.</small>
      </p>
        <p>Voulez-vous participer de nouveau à la promotion?</p>
      </div>
      <div id="upload-wrapper" v-if="upload_enabled">
        <div id="upload-original" v-if="upload_enabled !== 'upload_again'">
          <p>Cliquez sur « Télécharger » ci-dessous pour soumettre votre preuve d’achat et recevoir votre livre!</p>
          <snipp-upload :campaign-id="campaignId" include-upload-button="true" language="fr"
            @snipp-upload-result="handleSnippUploadResult" custom-css="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/kellogs_overrides.css"></snipp-upload>
        <div class="mt-5 mb-5 col-md-8 mx-auto">
      <p>Remarque: <br>SEULS les formats de fichier JPEG, PNG et PDF sont acceptés. <br>Maximum de 6 Mo par photo / preuve d’achat.</p>
      <p>Conseil: <br>Si la preuve d’achat est trop longue pour figurer sur une seule image, veuillez la plier pour montrer les éléments suivants: les produits admissibles achetés, le nom du magasin et la date d’achat.</p>
      <p>Si vous avez de la difficulté à téléverser la preuve d’achat, vous pouvez envoyer votre soumission par la poste (veuillez vous reporter à la dernière question de la FAQ).</p>
      <p><router-link to="/ParticipatingProducts">Cliquez ici pour voir tous les produits participants.</router-link></p>
        <p>Que dois-je faire si je n'arrive pas à participer à la promotion? <br><router-link to="/Support">Visitez notre FAQ</router-link>.</p>
    </div>
        </div>
        <div id="upload-again" v-if="upload_enabled === 'upload_again'">
          <snipp-upload :campaign-id="campaignId" include-upload-again-button="true" language="fr"
            @snipp-upload-result="handleSnippUploadResult" custom-css="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/kellogs_overrides.css"></snipp-upload>
            <p class="mt-3">Visitez le site <a href="https://www.wkkellogg.ca/fr-ca/accueil.html" target="_blank">WKKellogg.ca</a> pour obtenir des recettes, des nouvelles et des renseignements sur les produits.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageLoader from '../../../components/UI/PageLoader.vue';
export default {
  components: { PageLoader },
  props: ['campaignId'],
  emits: [],
  data() {
    return {
      ts: Date.now(),
      isLoading: true,
      is_loaded: false,
      is_logged_out: undefined,
      upload_enabled: undefined,
      registration_enabled: false,
      show_confirmation: undefined,
      user_status: undefined,
    };
  },
  methods: {
    showRegistration(confirmation) {
      if (this.upload_enabled) this.upload_enabled = undefined;
      if (confirmation) {
        this.show_confirmation = true;
      } else {
        this.show_confirmation = undefined;
      }
      this.registration_enabled = true;
    },
    showConfirmation() {
      this.showRegistration(true);
    },
    handleSnippLogout() {
      this.upload_enabled = undefined;
      this.is_logged_out = true;
      this.showRegistration();
    },
    handleIsRegistered() {
      this.user_status = Date.now();
      this.upload_enabled = 'upload';
      this.registration_enabled = false;
    },
    handleSnippUploadResult() {
      this.upload_enabled = 'upload_again';
    },
    handleCurrentUser() {
      this.registration_enabled = false;
      this.upload_enabled = 'upload';
    },
  },
  mounted() {
    this.is_loaded = true;
    setTimeout(() => (this.isLoading = false), 1500);
  },
};
</script>

<style scoped>
.wrapper {
  margin-top: 57px;
}
</style>
