<template>
  <div class="container text-center mb-5 pb-5">
    <h1 class="text-uppercase mt-5 mb-5">Produits participants</h1>
    <div class="row justify-content-center">
      <div v-for='pproduct in pproducts' :key='pproduct' class="col-md-4 mb-4"> 
      <p v-html="pproduct.img">
         
      </p>
      <p v-html="pproduct.title"></p>
      </div> 
    </div>
    </div>
</template>
  
  <script> 
  export default { 
    name: 'pp', 
    data () { 
    return { 
      pproducts: [ 
      {
          img:
            '<img src="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/pp1.png" class="img-fluid" alt="Kellogg&apos;s Frosted Flakes* cereal 580 g" > ',
          title:
            'Céréales Kellogg’s Frosted Flakes<sup>®</sup> 580&nbsp;g',
        },
        {
          img:
            '<img src="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/pp2.png" class="img-fluid" alt="Kellogg&apos;s Corn Flakes* cereal 600 g" > ',
          title:
            'Céréales Kellogg’s Corn Flakes<sup>®</sup> – 600&nbsp;g',
        },
        {
          img:
            '<img src="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/pp3.png" class="img-fluid" alt="Corn Pops* cereal 515 g" > ',
          title:
            'Céréales Corn Pops<sup>®</sup> 515&nbsp;g',
        },
        {
          img:
            '<img src="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/pp4.png" class="img-fluid" alt="Rice Krispies* cereal 560 g" > ',
          title:
            'Céréales Rice Krispies<sup>®</sup> 560&nbsp;g',
        },
        {
          img:
            '<img src="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/pp5.png" class="img-fluid" alt="Froot Loops* cereal 480 g" > ',
          title:
            'Céréales Froot Loops<sup>®</sup> 480&nbsp;g',
        },
        {
          img:
            '<img src="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/pp6.png" class="img-fluid" alt="Krave* 525 g" > ',
          title:
            'Céréales Krave<sup>®</sup> Saveur de chocolat – 525&nbsp;g',
        },
        {
          img:
            '<img src="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/pp7.png" class="img-fluid" alt="Mini-Wheats* Brown Sugar cereal 650 g" > ',
          title:
            'Céréales Mini-Wheats<sup>®</sup> Saveur de cassonade 650&nbsp;g',
        },
        {
          img:
            '<img src="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/pp8.png" class="img-fluid" alt="Mini-Wheats* Original cereal 650 g" > ',
          title:
            'Céréales Mini-Wheats<sup>®</sup> Originale, 650&nbsp;g',
        },
      ] 
    } 
    } 
  } 
  </script>
  