<template>
  <!-- Pre-program content -->
  <main role="main" class="container">
    <div class="text-center mt-5 mb-5 pb-5">
      <h1 class="text-uppercase text-center mb-4">
        La promotion est maintenant terminée.
      </h1>
      <p class="mb-4">Nous sommes désolés, mais cette promotion est terminée. <br>Les reçus doivent être soumis au plus
        tard le {{ endDate }} pour être admissibles à cette promotion.
      </p>
      <p>
        Visitez le site <a href="https://www.wkkellogg.ca/fr-ca/accueil.html" target="_blank">WKKellogg.ca</a> pour en savoir plus sur nos
        produits actuels et nos futures promotions.
      </p>
    </div>
  </main>
</template>
<script>
export default {
  props: {
    endDate: {
      type: Date,
      required: true,
    },
  },
};
</script>
