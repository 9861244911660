<template>
  <!-- Pre-program content -->
  <main role="main" class="container">
    <div class="text-center mt-5 mb-5 pb-2">
      <h1 class="text-uppercase text-center mb-4">
        Restez à l’affût !
      </h1>
      <p class="mb-4">
        Avez-vous déjà acheté 2 boîtes de céréales Kellogg’s<sup>®</sup> spécialement identifiées? Assurez-vous de
        conserver votre preuve d’achat et de revenir le {{ startDate }} afin de la téléverser pour recevoir votre livre.
      </p>
      <p>
        Pour en savoir plus, consultez toutes les <a href="https://snipp.us/!ctbeL" target="_blank">modalités</a> de
        l’offre.
      </p>
    </div>

    <h2 class="text-center mb-4">Comment participer</h2>

    <div class="row text-center mb-5 justify-content-center htsect">
      <div class="col-md-3">
        <div class="">
          <img src="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/shop.png"
            alt="MAGASINEZ" class="img-fluid mb-3" />
          <h3>MAGASINEZ</h3>
          <p>
            Achetez 2 boîtes spécialement identifiées de céréales Kellogg’s<sup>®</sup> d’ici le 31 janvier 2025 en
            une seule transaction.
          </p>
        </div>
      </div>

      <div class="col-md-3">
        <div class="">
          <img src="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/snap.png"
            alt="PRENEZ UNE PHOTO" class="img-fluid mb-3" />
          <h3>PRENEZ UNE PHOTO</h3>
          <p>
            Prenez une photo claire de votre preuve d’achat complète.
          </p>
        </div>
      </div>

      <div class="col-md-3">
        <div class="">
          <img src="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/upload.png"
            alt="TÉLÉVERSEZ ET INSCRIVEZ-VOUS" class="img-fluid mb-3" />
          <h3>TÉLÉVERSEZ ET INSCRIVEZ-VOUS</h3>
          <p>
            Fournissez vos renseignements personnels et téléversez votre preuve d’achat.
          </p>
        </div>
      </div>

      <div class="col-md-3">
        <div class="">
          <img src="https://snippcheck.blob.core.windows.net/files/kelloggs-feeding-reading-2007630/book.png"
            alt="CHOISISSEZ" class="img-fluid mb-3" />
          <h3>CHOISISSEZ</h3>
          <p>
            Après vérification de votre preuve d’achat, vous recevrez un courriel de confirmation et une demande de
            sélection de votre livre.
          </p>
        </div>
      </div>
    </div>

  </main>
</template>
<script>
export default {
  props: {
    startDate: {
      type: Date,
      required: true,
    },
  },
};
</script>
<style scoped>
.navbar-nav {
  display: none;
}
</style>
